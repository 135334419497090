import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Components/App';
import { AuthProvider } from "@asgardeo/auth-react";
import { AUTH_CONFIG } from './config';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider config={AUTH_CONFIG}>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


