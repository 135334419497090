import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ErrorDialog(props) {
    const { isVisible, handleClose, error } = props;

    return (
        <Dialog
            open={isVisible}
            onClose={()=>{handleClose(false)}}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
        >
            <DialogTitle id="error-dialog-title">{"Something went wrong!"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="error-dialog-description">
                    The application ran into an issue. {error ? error : ""} Please contact Digital Operations if this continues to happen."
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{handleClose(false)}} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}