import { HTTP, AUTH, OAUTH_CONFIG } from '../config';
var AuthConstants = require('./auth-constants.json');

var getAccessTokenFunction = null;
var refreshAccessTokenFunction = null;
var sessionClearFunction = null;

export function setGetAccessTokenFunction(getAccessToken) {
    getAccessTokenFunction = getAccessToken;
}

export function setRefreshAccessTokenFunction(refreshAccessToken) {
    refreshAccessTokenFunction = refreshAccessToken;
}

export function setSessionClearFunction(sessionClearFn) {
    sessionClearFunction = sessionClearFn;
}

export function callAPI(fetchURL, HTTPRequestMethod, payload, callback) {
    var retryCountCounter = 0;
    callAPIInternal(fetchURL, HTTPRequestMethod, payload, callback, retryCountCounter);
}


async function callAPIInternal(fetchURL, HTTPRequestMethod, payload, callback, retryCountCounter) {
    let headers = new Headers();
    let options = {};
    let body;
    let accessToken = await getAccessTokenFunction();
    headers.append(AuthConstants.header.HTTP_AUTHORIZATION_REQUEST_HEADER, AuthConstants.header.HTTP_AUTHORIZATION_HEADER_TYPE_BEARER + " " + accessToken);
    headers.append("Accept", "application/json");
    options['method'] = HTTPRequestMethod;
    if (payload && HTTPRequestMethod !== HTTP.HTTP_REQUEST_METHOD_GET) {
        headers.append("Content-Type", payload.contentType);
        if (payload.contentType === AUTH.HEADERS.X_WWW_FORM_URLENCODED) {
            body = new URLSearchParams();
            Object.keys(payload['body']).forEach((key) => {
                body.append(key, payload.body[key]);
            });
            options['body'] = body;
        } else {
            options['body'] = JSON.stringify(payload.body);
        }
    }
    options['headers'] = headers;
    const fetchResult = fetch(fetchURL, options);
    var response;
    var jsonData;
    try {
        response = await fetchResult;
        jsonData = await response.json();
    } catch (error) {
        callback(AuthConstants.null.DATA_NULL, error);
        return;
    }

    if (response.ok) {
        if (callback != null) {
            callback(jsonData, AuthConstants.null.ERROR_NULL);
        }
    } else if (response.status === 401) {
        if (retryCountCounter < OAUTH_CONFIG.token_refresh_retry_count_MAX) {
            retryCountCounter += 1;

            try {
                const newAccessToken = await refreshAccessTokenFunction();
            }catch (error) {
                sessionClearFunction();
            }
            callAPIInternal(fetchURL, HTTPRequestMethod, payload, callback, retryCountCounter);
        } else {

            if (callback != null) {
                callback(AuthConstants.null.DATA_NULL, "Max retry count exceeded for API call.");
            }
            sessionClearFunction();
        }

    } else {
        if (callback != null) {
            callback(AuthConstants.null.DATA_NULL, response.statusText);
        }
        console.error('Internal server error');
    }
}

