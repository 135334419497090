import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MailIcon from '@material-ui/icons/Mail';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import Badge from '@material-ui/core/Badge';
import ClearAllIcon from '@material-ui/icons/ClearAll';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    defaultBadge: {
        backgroundColor: "#00AFD7",
        color: "white"
    },
}));

export default function GroupsActionToolbar(props) {
    const classes = useStyles();
    const { checkedPublicGroups, selected, handleClearSelectedPublicGroups } = props;
    const toSubscribeCount = selected.toSubscribe.length;
    const toUnsubscribeCount = selected.toUnsubscribe.length;

    return (
        <div className={classes.root}>
            <Grid container spacing={2} direction="row" justify="flex-end">
                <Grid item>
                    <Badge badgeContent={toSubscribeCount + toUnsubscribeCount}
                        classes={{ badge: classes.defaultBadge }} 
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            startIcon={<ClearAllIcon />}
                            disabled={selected.toSubscribe< 1 && toUnsubscribeCount < 1}
                            onClick={()=>{handleClearSelectedPublicGroups(true)}}
                        >
                            Clear All Selections
                        </Button>
                    </Badge>
                </Grid>
                <Grid item>
                    <Badge badgeContent={toSubscribeCount} classes={{ badge: classes.defaultBadge }} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<MailIcon />}
                            disabled={toSubscribeCount < 1}
                            onClick={()=>{props.handleSubscriptionDialog(true, "subscribe", selected.toSubscribe)}}
                        >
                            Subscribe
                        </Button>
                    </Badge>
                </Grid>
                <Grid item>
                    <Badge badgeContent={toUnsubscribeCount} classes={{ badge: classes.defaultBadge }} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<UnsubscribeIcon />}
                            disabled={toUnsubscribeCount < 1}
                            onClick={()=>{props.handleSubscriptionDialog(true, "unsubscribe", selected.toUnsubscribe)}}
                        >
                            Unsubscribe
                        </Button>
                    </Badge>

                </Grid>
            </Grid>
        </div>
    );
}