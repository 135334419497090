import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CheckboxList from './CheckboxList';
import SubheaderDividers from './SubheaderDividers';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import GroupsActionToolbar from './GroupsActionToolbar';
import GroupsText from './GroupsText';
import PrivateGroupsList from './PrivateGroupsList';
import DefaultGroupsList from './DefaultGroupsList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const getCustomLists = (publicGroups, checkedCustom, handleSelectCustom, handleSubscriptionDialog) => {
  var length = publicGroups.length;
  if (length === 0) {
    return (
      <GroupsText text={"No groups to show"} />
    );
  }
  var columns = 3;
  var maxItems = Math.floor(length / columns);
  var end = 0;
  if (maxItems > 20) {
    if (length % columns !== 0) {
      end = maxItems + 1;
    } else {
      end = maxItems;
    }
  } else {
    end = 20;
  }
  var returnElements = [];
  for (var i = 0; i <= (columns - 1); i++) {
    returnElements.push(
      <Grid key={i} item xs={12} md={(12 / columns)}>
        <CheckboxList groups={publicGroups.slice(i * end, end * (i + 1))} isCustom={true} checked={checkedCustom} handleSelectCustom={handleSelectCustom} handleSubscriptionDialog={handleSubscriptionDialog} />
      </Grid>);
  }

  return <Grid container spacing={2}>{returnElements}</Grid>;
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label={<Badge badgeContent={props.defaultGroups.length + props.publicGroups.length + props.privateGroups.length} color="secondary">All Groups</Badge>} {...a11yProps(0)} />
          <Tab label={<Badge badgeContent={props.defaultGroups.length} color="secondary">Default Groups</Badge>} {...a11yProps(1)} />
          <Tab label={<Badge badgeContent={props.publicGroups.length} color="secondary">Public Groups</Badge>} {...a11yProps(2)} />
          <Tab label={<Badge badgeContent={props.privateGroups.length} color="secondary">Private Groups</Badge>} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} children={<SubheaderDividers defaultGroups={props.defaultGroups} publicGroups={props.publicGroups} privateGroups={props.privateGroups} checkedCustom={props.checkedCustom} handleSelectCustom={props.handleSelectCustom}
        selected={props.selected}
        handleSubscriptionDialog={props.handleSubscriptionDialog}
        handleClearSelectedPublicGroups={props.handleClearSelectedPublicGroups}
      />}>

      </TabPanel>
      <TabPanel value={value} index={1} children={<Paper className="email-groups" elevation={3}>{
        props.defaultGroups.length > 0 ? <DefaultGroupsList groups={props.defaultGroups} /> : <GroupsText text={"No groups to show"} />
      }</Paper>}>
        {/* {getButtons(classes)} */}
        {/* {props.defaultGroups.length !== 0 ? <CheckboxList groups={props.defaultGroups} isCustom={false} /> : "No groups"} */}
      </TabPanel>
      <TabPanel value={value} index={2} children={
        <React.Fragment>
          <Grid container alignItems="center" spacing={3} style={{marginBottom:6}}>
            <Grid item xs={12}>
              <GroupsActionToolbar checkedPublicGroups={props.checkedCustom} selected={props.selected} handleClearSelectedPublicGroups={props.handleClearSelectedPublicGroups} handleSubscriptionDialog={props.handleSubscriptionDialog} />
            </Grid>
          </Grid>
          <Paper className="email-groups" elevation={3}>{getCustomLists(props.publicGroups, props.checkedCustom, props.handleSelectCustom, props.handleSubscriptionDialog)}</Paper>
        </React.Fragment>}>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Paper>
        <PrivateGroupsList groups={props.privateGroups} handleSubscriptionDialog={props.handleSubscriptionDialog}/>
        </Paper>
      </TabPanel>
    </div>
  );
}