import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TabPanel from './TabPanel';
import GroupsFilterToolbar from './GroupsFilterToolbar';


const useStyles = makeStyles((theme) => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
}));

export default function MyGroups(props) {
  const classes = useStyles();
  const { searchOnChange, defaultGroups, groupsShown, publicGroups, privateGroups, handleSelectCustom, checkedCustom, handleShownGroupsChange,
    handleSubscriptionDialog, toSubscribe, toUnsubscribe, handlers, search } = props;

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <GroupsFilterToolbar search={search} groups={groupsShown} handleShownGroupsChange={handleShownGroupsChange} searchOnChange={searchOnChange} />
      </AppBar>
      {/* <AppBar></AppBar> */}
      <div className={classes.contentWrapper}>
        <TabPanel defaultGroups={defaultGroups} publicGroups={publicGroups} checkedCustom={checkedCustom} handleSelectCustom={handleSelectCustom}
          privateGroups={privateGroups}
          selected={
            {
              toSubscribe: toSubscribe,
              toUnsubscribe: toUnsubscribe
            }
          }
          handleSubscriptionDialog={handleSubscriptionDialog}
          handleClearSelectedPublicGroups={handlers.handleClearSelectedPublicGroups}
        />
        {/* <Typography color="textSecondary" align="center">
            No users for this project yet
          </Typography> */}
        {/* <TabPanel defaultGroups={props.defaultGroups} publicGroups={props.publicGroups} checkedCustom={checkedCustom} handleSelectCustom={props.handleSelectCustom}/> */}
      </div>
    </Paper>
  );
}

// MyGroups.propTypes = {

// };