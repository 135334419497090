import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import { green } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
}));

export default function PrivateGroupsList(props) {
    const classes = useStyles();
    const { groups } = props;

    const changeToSubscribedButton = (e) => {
        if (e.target.className.includes("MuiButtonBase-root")) {
            e.target.children[0].textContent = "Subscribed";
        } else if (e.target.className.includes("MuiButton-label")) {
            e.target.textContent = "Subscribed";
        }
    };

    const changeToUnsubscribeButton = (e) => {
        if (e.target.className.includes("MuiButtonBase-root")) {
            e.target.children[0].textContent = "Unsubscribe";
        } else if (e.target.className.includes("MuiButton-label")) {
            e.target.textContent = "Unsubscribe";
        }
    };


    return (
        <List className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    {groups.map((group) => {
                        const labelId = `private-list-label-${group.name}`;

                        return (
                            <React.Fragment key={group.key}>
                                <ListItem key={group.key} role={undefined} dense button>
                                    <ListItemIcon>
                                        <LockIcon style={{ color: green[500] }} />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={`${group.name}`} />
                                    <ListItemSecondaryAction>
                                        <Button className="subscribed-button" size="small" variant="contained" color="primary" onMouseEnter={changeToUnsubscribeButton} onMouseLeave={changeToSubscribedButton}
                                            onClick={() => { props.handleSubscriptionDialog(true, "unsubscribe", [group.name]) }}
                                        >
                                            Subscribed
                                        </Button>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        );
                    })}

                </Grid>
            </Grid>
        </List>
    );
}