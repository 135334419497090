export const APP_NAME = "Email Group Manager";

export const AUTH = {
    HEADERS: {
        HTTP_AUTHORIZATION_REQUEST_HEADER: "Authorization",
        HTTP_AUTHORIZATION_HEADER_TYPE_BEARER: "Bearer",
        HTTP_AUTHORIZATION_HEADER_TYPE_BASIC: "Basic",
        APPLICATION_JSON: "application/json",
        X_WWW_FORM_URLENCODED: "application/x-www-form-urlencoded"
    },
    USER: {
        username: "",
        password: "",
    }
};

export const HTTP = {
    HTTP_REQUEST_METHOD_GET: "GET",
    HTTP_REQUEST_METHOD_POST: "POST",
    HTTP_REQUEST_METHOD_PATCH: "PATCH",
};

export const APIS = {
    GET_EMAIL_GROUPS: window.config?.BACKEND_API_BASE_URL + "/user-google-groups",
    GET_DEFAULT_EMAIL_GROUPS: window.config?.BACKEND_API_BASE_URL + "/default-google-groups",
    GET_PUBLIC_GROUPS: window.config?.BACKEND_API_BASE_URL + "/all-google-groups",
    SUBSCRIBE_TO_GROUPS: window.config?.BACKEND_API_BASE_URL + "/google-group/subscribe",
    UNSUBSCRIBE_FROM_GROUPS: window.config?.BACKEND_API_BASE_URL + "/google-group/unsubscribe",
    GET_USER_INFO: window.config?.BACKEND_API_BASE_URL + "/user-info",
};

export const OAUTH_CONFIG = {
    token_refresh_retry_count_MAX: 3,
};

export const AUTH_CONFIG = {
    clientID: window.config?.AUTH_CLIENT_ID,
    baseUrl: window.config?.AUTH_SERVER_ORIGIN,
    signInRedirectURL: window.config?.AUTH_SIGN_IN_REDIRECT_URL,
    signOutRedirectURL: window.config?.AUTH_SIGN_OUT_REDIRECT_URL,
    scope: ["openid", "email", "groups"],
};

export const APP_CONFIG = {
    USER_GUIDE_URL: window.config?.USER_GUIDE_URL,
    GET_HELP_EMAIL_TO: window.config?.GET_HELP_EMAIL_TO_ADDRESS,
    GET_HELP_EMAIL_SUBJECT: "[HELP] WSO2 Email Group Manager",
}
