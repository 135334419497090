import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default function SubscriptionActionDialog(props) {

  const classes = useStyles();
  const { onClose, messageType, type, groups, employee, callback } = props;
  var listId = 0;

  return (
    <div>
      <Dialog onClose={() => { onClose(false) }} aria-labelledby="customized-dialog-title" open={props.isVisible} fullWidth={true} maxWidth={"md"}>
        <DialogTitle id="customized-dialog-title" onClose={() => { onClose(false) }}>
          Please confirm your actions {employee? "for the employee "+employee:""}
        </DialogTitle>
        <MuiDialogContent dividers>
          <Typography gutterBottom>
            Do you wish to {type} {type === 'unsubscribe' ? "from" : "to"} the following group(s)?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <List className={classes.root}>
                {groups.map((group) => {
                  return (
                    <ListItem key={listId++} dense>
                      <ListItemText key={listId}
                        primary={group}
                      />
                    </ListItem>
                  );
                })}

              </List>
            </Grid>
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={() => { onClose(false) }} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={() => {
            if(type === 'subscribe'){
              props.subscribeToGroups(groups);
            } else if (type === 'unsubscribe'){
              props.unsubscribeFromGroups(groups);
            }
            onClose(false);
          }
          } color="primary">
            Confirm
          </Button>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
}