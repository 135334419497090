import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
}));

export default function CheckboxList(props) {
  const { checked, handleSelectCustom, groups } = props;
  const [setChecked] = React.useState([0]);
  const classes = useStyles();

  const changeToSubscribedButton = (e) => {
    if (e.target.className.includes("MuiButtonBase-root")) {
      e.target.children[0].textContent = "Subscribed";
    } else if (e.target.className.includes("MuiButton-label")) {
      e.target.textContent = "Subscribed";
    }
  };

  const changeToUnsubscribeButton = (e) => {
    if (e.target.className.includes("MuiButtonBase-root")) {
      e.target.children[0].textContent = "Unsubscribe";
    } else if (e.target.className.includes("MuiButton-label")) {
      e.target.textContent = "Unsubscribe";
    }
  };

  const changeToSubscribeButton = (e) => {
    console.log("THIS IS RUN");
    if (e.target.className.includes("MuiButtonBase-root")) {
      e.target.children[0].textContent = "Subscribe";
    } else if (e.target.className.includes("MuiButton-label")) {
      e.target.textContent = "Subscribe";
    }
  };


  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    handleSelectCustom(newChecked);
  };

  return (
    <List className={classes.root}>
      {props.groups.map((group) => {
        const labelId = `checkbox-list-label-${group.name}`;

        return (
          <React.Fragment key={group.key}>
            <ListItem key={group.key} role={undefined} dense button onClick={handleToggle(group.name)}>
              <ListItemIcon>
                {props.isCustom ? <Checkbox
                  edge="start"
                  checked={checked.indexOf(group.name) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                /> : <CheckCircleIcon style={{ color: green[500] }} />}
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${group.name}`} />
              <ListItemSecondaryAction>
                {props.isCustom ? group.isSubscribed ?
                  <Button className="subscribed-button" size="small" variant="contained" color="primary" onMouseEnter={changeToUnsubscribeButton} onMouseLeave={(e)=>{changeToSubscribedButton(e, group.isSubscribed)}}
                    onClick={(e) => { props.handleSubscriptionDialog(true, "unsubscribe", [group.name]) }}
                  >
                    Subscribed
                  </Button>
                  :
                  <Button size="small" variant="outlined" color="primary" onClick={(e) => { 
                    props.handleSubscriptionDialog(true, "subscribe", [group.name]);
                  }}>
                    Subscribe
                  </Button> : ""}
              </ListItemSecondaryAction>
            </ListItem>
            <Divider component="li" />
          </React.Fragment>
        );
      })}
    </List>
  );
}