/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SearchIcon from '@material-ui/icons/Search';
import Toolbar from '@material-ui/core/Toolbar';
import FormLabel from '@material-ui/core/FormLabel';


const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 16px',
    },
}));

export default function GroupsFilterToolbar(props) {
    const classes = useStyles();
    const { searchOnChange, groups, handleShownGroupsChange, search } = props;

    return (
        <Toolbar>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <SearchIcon className={classes.block} color="inherit" />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder="Search by keyword"
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={searchOnChange}
                        value={search}
                    />
                </Grid>
                <Grid item>
                </Grid>
                <Grid>
                    <FormLabel component="legend">Groups shown:</FormLabel>
                </Grid>
                <Grid>
                    <RadioGroup aria-label="groupType" name="groupType" value={groups} onChange={handleShownGroupsChange}>
                        <FormControlLabel value="all" control={<Radio />} label="All" />
                        <FormControlLabel value="subscribed" control={<Radio />} label="Subscribed" />
                        <FormControlLabel value="unsubscribed" control={<Radio />} label="Unsubsribed" />
                    </RadioGroup>
                </Grid>
            </Grid>
        </Toolbar>
    );
}
