import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { green } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


export default function DefaultGroupsList(props) {
    const { groups } = props;

    return (
        <List>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    {groups.map((group) => {
                        const labelId = `private-list-label-${group.name}`;

                        return (
                            <React.Fragment key={group.key}>
                                <ListItem key={group.key} role={undefined} dense button>
                                    <ListItemIcon>
                                        <CheckCircleIcon style={{ color: green[500] }} />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={`${group.name}`} />
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        );
                    })}

                </Grid>
            </Grid>
        </List>
    );
}