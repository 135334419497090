import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import MyGroups from './MyGroups';
import Header from './Header';
import SubscriptionActionDialog from './SubscriptionActionDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import ErrorDialog from './ErrorDialog';

function Copyright() {
  return (

    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        WSO2 Inc
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#0a3d62',
      dark: '#006db3',
    },
    // secondary: {
    //   main: '#eb2f06',
    // },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
      outlinedSizeSmall: {
        paddingTop: 1,
        paddingBottom: 1,
        paddingRight: 7,
        paddingLeft: 7,
      },
      containedSizeSmall: {
        paddingTop: 2,
        paddingBottom: 2,
        paddingRight: 7,
        paddingLeft: 7,
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
        overflow: 'visible',
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiListItem: {
      dense: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: "row",
        paddingLeft: 15,
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
      },
    },
    // MuiButtonBase:{
    //  root: {
    //   minWidth: 100,
    //  },
    // },
    // MuiBox: {
    //   root: {
    //     padding: 100,
    //   }
    // },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(3, 4),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function Paperbase(props) {
  const { classes, publicGroups, defaultGroups, search, searchOnChange, checkedCustom, privateGroups, user } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        {/* <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator PaperProps={{ style: { width: drawerWidth } }} />
          </Hidden>
        </nav> */}
        <div className={classes.app}>
          <Header onDrawerToggle={handleDrawerToggle} user={user} handleLogout={props.handleLogout} />
          <AppBar
            component="div"
            className={classes.secondaryBar}
            color="primary"
            position="static"
            elevation={0}
          >
            <Tabs value={value} textColor="inherit" onChange={handleTabChange}>
              <Tab textColor="inherit" label="My Groups" />
            </Tabs>
          </AppBar>
          <main className={classes.main}>
            <TabPanel value={value} index={0} children={
              <MyGroups defaultGroups={defaultGroups} publicGroups={publicGroups} searchOnChange={searchOnChange} groupsShown={props.groupsShown} handleShownGroupsChange={props.handleShownGroupsChange}
                checkedCustom={checkedCustom}
                handleSelectCustom={props.handleSelectCustom}
                publicGroupsMap={props.publicGroupsMap}
                toSubscribe={props.toSubscribe}
                toUnsubscribe={props.toUnsubscribe}
                handleSubscriptionDialog={props.subscriptionDialog.handleSubscriptionDialog}
                handlers={props.myGroups.handlers}
                privateGroups={props.myGroups.groups.privateGroups}
                search={search} />}
            >
            </TabPanel>
          </main>
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </div>
      </div>
      <SubscriptionActionDialog isVisible={props.subscriptionDialog.isSubscriptionDialogVisible} onClose={props.subscriptionDialog.handleSubscriptionDialog}
        type={props.subscriptionDialog.subscriptionDialogActionType}
        groups={props.subscriptionDialog.groups}
        subscribeToGroups={props.subscriptionDialog.subscribeToGroups}
        unsubscribeFromGroups={props.subscriptionDialog.unsubscribeFromGroups}
        employee={""}
        callback={props.subscriptionDialog.callback} />
      {props.isLoading ?
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={props.isLoading}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={true}>
            <CircularProgress style={{
              outline: "none",
              boxShadow: "none",
            }} />
          </Fade>
        </Modal> :
        <React.Fragment />}
      <ErrorDialog isVisible={props.errorDialog.isVisible} handleClose={props.errorDialog.handleClose} error={props.errorDialog.message} />
    </ThemeProvider>
  );
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);