import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import PaperBase from './PaperBase';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  Button
} from "@material-ui/core"
import { callAPI, setGetAccessTokenFunction, setRefreshAccessTokenFunction, setSessionClearFunction } from '../Services/OAuthClient';
import { useAuthContext } from "@asgardeo/auth-react";
import CircularProgressIndeterminate from './CircularProgressIndeterminate';
import { APIS, APP_NAME, HTTP, AUTH } from '../config';

const App = (props) => {

  const [userEmail, setUserEmail] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [defaultGroups, setDefaultGroups] = useState([]);
  const [publicGroups, setPublicGroups] = useState([]);
  const [subscribedGroups, setSubscribedGroups] = useState([]);
  const [privateGroups, setPrivateGroups] = useState([]);
  const [search, setSearch] = useState("");
  const [shownDefaultGroups, setShownDefaultGroups] = useState([]);
  const [shownPublicGroups, setShownPublicGroups] = useState([]);
  const [shownPrivateGroups, setShownPrivateGroups] = useState([]);
  const [groupsShown, setGroupsShown] = useState("all");
  const [checkedCustom, setCheckedCustom] = useState([]);
  const [toSubscribe, setToSubscribe] = useState([]);
  const [toUnsubscribe, setToUnsubscribe] = useState([]);
  const [publicGroupsMap, setPublicGroupsMap] = useState({});
  const [isSubscriptionDialogVisible, setIsSubscriptionDialogVisible] = useState(false);
  const [subscriptionDialogActionType, setSubscriptionDialogActionType] = useState("");
  const [subscriptionDialogGroups, setSubscriptionDialogGroups] = useState([]);
  const [subscriptionDialogCallback, setSubscriptionDialogCallback] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resetFilters, setResetFilters] = useState(false);

  const subscriptionDialogRef = useRef();

  const { state, signIn, signOut, getBasicUserInfo, getIDToken, getDecodedIDToken, refreshAccessToken, getAccessToken, revokeAccessToken, } = useAuthContext();

  const [loadApp, setLoadApp] = useState(false);
  const [idToken, setIdToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const setIsInitLogin = (value) => {
    sessionStorage.setItem("isInitLogin", value)
  };

  const getIsInitLogin = () => {
    if (sessionStorage.getItem("isInitLogin") === "true") {
      return true;
    } else {
      return false;
    }
  };

  const setIsLoggedOut = (value) => {
    sessionStorage.setItem("isLoggedOut", value);
    setLoggedOutState(value === "true");
  };

  const getIsLoggedOut = () => {
    if (sessionStorage.getItem("isLoggedOut") === "true") {
      return true;
    } else {
      return false;
    }
  };

  const setIsSessionTimeOut = (value) => {
    sessionStorage.setItem("isSessionTimeOut", value)
  };

  const getIsSessionTimeOut = () => {
    if (sessionStorage.getItem("isSessionTimeOut") === "true") {
      return true;
    } else {
      return false;
    }
  };

  const [loggedOutState, setLoggedOutState] = useState(getIsLoggedOut());

  const handleLogin = () => {
    setIsInitLogin("true");
    setIsSessionTimeOut("false");
    setIsLoggedOut("false");

    signIn().catch(e => {
      console.error(e);
    })
  }

  const handleLogout = () => {
    signOut().catch(e => {
    })

    setIsInitLogin("false");
    setIsLoggedOut("true");
    setIsSessionTimeOut("false");
  };

  useEffect(() => {
    if (!getIsLoggedOut() && !(state?.isLoading || state?.isAuthenticated)) {
      handleLogin();
    }
  }, [state.isLoading, state.isAuthenticated]);

  useEffect(() => {
    if (state && state.isAuthenticated) {

      const getUserData = async () => {
        const idToken = await getIDToken();
        const accessToken = await getAccessToken();
        const decodedIDToken = await getDecodedIDToken();

        setIdToken(idToken);
        setAccessToken(accessToken);
        setGetAccessTokenFunction(getAccessToken)
        setRefreshAccessTokenFunction(refreshAccessToken)
        setSessionClearFunction(sessionClearFn)
        setLoadApp(true);
        
        if (decodedIDToken && decodedIDToken.email) {
          setUserEmail(decodedIDToken.email);
        }
      };

      getUserData();
    }
  }, [state.isAuthenticated, state.isLoading]);

  const sessionClearFn = () => {
    setLoadApp(false);
    setIsInitLogin("false");
    setIsSessionTimeOut("true");
    setIsLoggedOut("true");

    revokeAccessToken().catch(e => {
      console.error(e);
    });
  }

  //function to get user info
  const getUserInfo = () => {
    callAPI(APIS.GET_USER_INFO, HTTP.HTTP_REQUEST_METHOD_GET, null, (jsonData, error) => {
      if (jsonData) {
        setUserFullName(jsonData.firstName + " " + jsonData.lastName);
        setUserImage(jsonData.image);
        setIsAdmin(jsonData.isAdmin);
      }
    });
  }

  //function to refresh groups
  const myGroupsRefresh = () => {
    var defaultGroups = [];
    var publicGroups = [];

    setIsLoading(true);
    setShownPublicGroups([]);
    setShownPrivateGroups([]);
    setSearch("");
    setGroupsShown("all");
    setResetFilters(true);

    callAPI(APIS.GET_DEFAULT_EMAIL_GROUPS, HTTP.HTTP_REQUEST_METHOD_GET, null, (jsonData, error) => {
      if (!jsonData) {
        return;
      }
      defaultGroups = jsonData;
      if (defaultGroups) {
        var defaultGroupId = 0;
        defaultGroups = defaultGroups.map(defaultGroup => {
          return {
            key: defaultGroupId++,
            name: defaultGroup
          }
        });
        setDefaultGroups(defaultGroups);
        setShownDefaultGroups(defaultGroups);
      }
    });
    var publicGroupId = 0;
    var privateGroupId = 0;
    var publicGroupsMap = {};
    var privateGroups = [];
    var subscribedGroups = [];

    callAPI(APIS.GET_PUBLIC_GROUPS, HTTP.HTTP_REQUEST_METHOD_GET, null, (jsonData, error) => {
      if (!jsonData) {
        return;
      }
      publicGroups = jsonData;
      if (publicGroups) {
        publicGroups.forEach(publicGroup => {
          publicGroupsMap[publicGroup] = {
            key: publicGroupId++,
            name: publicGroup,
            isSubscribed: false
          }
        });

        callAPI(APIS.GET_EMAIL_GROUPS, HTTP.HTTP_REQUEST_METHOD_GET, null, (jsonData, error) => {
          if (!jsonData) {
            return;
          }
          subscribedGroups = jsonData;
          if (subscribedGroups) {
            subscribedGroups.forEach(subscribedGroup => {
              if (publicGroups.includes(subscribedGroup)) {
                publicGroupsMap[subscribedGroup].isSubscribed = true;
              } else {
                privateGroups.push({
                  key: privateGroupId++,
                  name: subscribedGroup
                });
              }
            });

            setPublicGroups(publicGroups);
            setShownPublicGroups(JSON.parse(JSON.stringify(Object.values(publicGroupsMap))));
            setPublicGroupsMap(publicGroupsMap);
            setPrivateGroups(privateGroups);
            setShownPrivateGroups(privateGroups);
            setIsLoading(false);
          }
        });
      }
      // this.setState({      
      //   isLoading: false
      // });
    });
  };

  //function to handle group subscription
  const subscribeToGroups = (groups) => {
    if (groups.length < 1) {
      myGroupsRefresh();
      setToSubscribe([]);
      setCheckedCustom(toUnsubscribe);
      return;
    }
    let group = groups.pop();
    let payload = {
      contentType: AUTH.HEADERS.APPLICATION_JSON,
      body: {
        groupName: group,
        userEmail: userEmail
      }
    };
    setIsLoading(true);

    callAPI(APIS.SUBSCRIBE_TO_GROUPS, HTTP.HTTP_REQUEST_METHOD_PATCH, payload, (jsonData, error) => {
      subscribeToGroups(groups);
    });
  };

  //function to handle group subscriptions removal
  const unsubscribeFromGroups = (groups) => {
    if (groups.length < 1) {
      myGroupsRefresh();
      setToUnsubscribe([]);
      setCheckedCustom(toSubscribe);
      return;
    }
    let group = groups.pop();
    let payload = {
      contentType: AUTH.HEADERS.APPLICATION_JSON,
      body: {
        groupName: group,
        userEmail: userEmail
      }
    };
    setIsLoading(true);
    callAPI(APIS.UNSUBSCRIBE_FROM_GROUPS, HTTP.HTTP_REQUEST_METHOD_PATCH, payload, (jsonData, error) => {
      unsubscribeFromGroups(groups);
    });
  };

  //function to handle search
  const searchOnChange = (e) => {
    var searchValue = e.target.value.toLowerCase();
    applyGroupFilters(searchValue, groupsShown);
  }

  //function to handle group filters
  const applyGroupFilters = (searchValue, groupsShownValue) => {
    var filteredDefaultGroups = defaultGroups.filter(defaultGroup => defaultGroup.name.includes(searchValue));
    var filteredPublicGroups = [];
    var filteredPrivateGroups = privateGroups.filter(privateGroup => privateGroup.name.includes(searchValue));
    Object.keys(publicGroupsMap).forEach((key) => {
      if (key.includes(searchValue)) {
        filteredPublicGroups.push(publicGroupsMap[key]);
      }
    });
    setShownDefaultGroups(groupsShownValue !== "unsubscribed" ? filteredDefaultGroups : []);
    setShownPublicGroups(filteredPublicGroups.filter(publicGroup => {
      if (groupsShownValue === "subscribed") {
        return publicGroup.isSubscribed;
      } else if (groupsShownValue === "unsubscribed") {
        return !publicGroup.isSubscribed;
      } else {
        return true;
      }
    }));
    setShownPrivateGroups(groupsShownValue !== "unsubscribed" ? filteredPrivateGroups : []);
    setSearch(searchValue);
    setGroupsShown(groupsShownValue);
    setResetFilters(false);
  };

  //function to set selected public groups
  const setSelectedPublicGroups = (groups) => {
    setCheckedCustom(groups);
    var toSubscribe = [];
    var toUnsubscribe = [];
    groups.forEach((group) => {
      if (publicGroupsMap[group] != null) {
        if (publicGroupsMap[group].isSubscribed) {
          toUnsubscribe.push(group);
        } else {
          toSubscribe.push(group);
        }
      }
    });
    setToSubscribe(toSubscribe);
    setToUnsubscribe(toUnsubscribe);
  };

  //function to clear selected public groups
  const handleClearSelectedPublicGroups = (isConfirmed) => {
    setCheckedCustom([]);
    setToSubscribe([]);
    setToUnsubscribe([]);
  }

  //function to handle shown groups change
  const handleShownGroupsChange = (e) => {
    var groupsShownValue = e.target.value;
    applyGroupFilters(search, groupsShownValue)
  }

  //function to handle subscription dialog
  const handleSubscriptionDialog = (setOpen, type, groups, callback) => {
    setIsSubscriptionDialogVisible(setOpen);
    setSubscriptionDialogActionType(type);
    setSubscriptionDialogGroups(setOpen ? groups : []);
  }

  //function to handle error dialog
  const handleErrorDialog = (isVisible, blockView, message) => {
    setIsError(isVisible);
    setIsLoading(blockView);
    setErrorMessage(message);
  }

  useEffect(() => {    
    if (loadApp && userEmail) {
      getUserInfo();
      myGroupsRefresh();
    }
  }, [loadApp, userEmail]);


  return (
    <div className="App">
      {state.isAuthenticated && loadApp ? (
        <PaperBase defaultGroups={shownDefaultGroups}
          publicGroups={shownPublicGroups}
          searchOnChange={searchOnChange}
          search={search}
          groupsShown={groupsShown}
          handleShownGroupsChange={handleShownGroupsChange}
          checkedCustom={checkedCustom}
          handleSelectCustom={setSelectedPublicGroups}
          publicGroupsMap={publicGroupsMap}
          toSubscribe={toSubscribe}
          toUnsubscribe={toUnsubscribe}
          myGroups={
            {
              groups: {
                privateGroups: shownPrivateGroups
              },
              handlers: {
                handleClearSelectedPublicGroups: handleClearSelectedPublicGroups
              },
              refs: {
                subscriptionDialogRef: subscriptionDialogRef
              }
            }
          }
          subscriptionDialog={{
            handleSubscriptionDialog: handleSubscriptionDialog,
            isSubscriptionDialogVisible: isSubscriptionDialogVisible,
            subscriptionDialogActionType: subscriptionDialogActionType,
            groups: subscriptionDialogGroups,
            subscribeToGroups: subscribeToGroups,
            unsubscribeFromGroups: unsubscribeFromGroups,
            callback: subscriptionDialogCallback
          }}
          isLoading={isLoading}
          user={{
            email: userEmail,
            name: userFullName,
            image: userImage,
            isAdmin: isAdmin
          }}
          errorDialog={{
            isVisible: isError,
            handleClose: handleErrorDialog,
            message: errorMessage
          }}
          handleLogout={handleLogout}
        />
      ) :
        (getIsLoggedOut() ? (
          <div style={{ backgroundColor: '#f4f6f8', height: '100vh' }}>
            <Box sx={{
              backgroundColor: '#f4f6f8',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              boxSizing: 'border-box',
            }}
            >
              <Container maxWidth="md">
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        p: 2
                      }}
                    >
                      <Grid container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={12}>
                          <img alt="logo" width="150" height="auto" src="https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"></img>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ pb: 2, }}
                        >
                          <Typography variant="h5">
                            {APP_NAME}
                          </Typography>
                        </Grid>
                        {(!(getIsInitLogin() || state.isLoading || state.isAuthenticated) || getIsSessionTimeOut()) ? (
                          <Grid item xs={12}>
                            <Button
                              id="login"
                              onClick={() => {
                                handleLogin();
                              }}
                              variant="contained"
                              color="secondary"
                              disabled={(getIsInitLogin() || state.isLoading || state.isAuthenticated) && !getIsSessionTimeOut()}
                            >
                              Log In
                            </Button>
                          </Grid>
                        ) :
                          (<Grid item xs={12}>
                            <Typography variant="caption">
                              <CircularProgressIndeterminate isLoading={true} />
                            </Typography>
                          </Grid>)}
                      </Grid>
                    </Box>
                  </CardContent>
                  <Divider />
                </Card>
              </Container>
            </Box>
          </div>
        ) :
          (
            <div style={{ backgroundColor: '#f4f6f8', height: '100vh' }}>
              <Box sx={{
                backgroundColor: 'background.default',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                boxSizing: 'border-box',
              }}
              >
                <Container maxWidth="md">
                  <Card>
                    <CardContent>
                      <Box
                        sx={{
                          p: 2
                        }}
                      >
                        <Grid container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}>
                          <Grid item xs={12}>
                            <img alt="logo" width="150" height="auto" src="https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"></img>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{ pb: 2, }}
                          >
                            <Typography variant="h5">
                              {APP_NAME}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="caption">
                              <CircularProgressIndeterminate isLoading={true} />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                    <Divider />
                  </Card>
                </Container>
              </Box>
            </div>
          ))
      }
    </div>
  )
}

export default App
