import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../images/logo.png';
import {
  FileText as FileTextIcon,
  HelpCircle as HelpIcon,
  LogOut as LogOutIcon,
} from 'react-feather';
import {
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core';
import { getGmailMailTo } from '../utils/formatting';
import { APP_CONFIG } from '../config';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const useStyles = makeStyles((theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const { user, handleLogout } = props;

  const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
  const openAccount = Boolean(accountAnchorEl);

  const handleClickAccount = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };
  const handleCloseAccount = () => {
    setAccountAnchorEl(null);
  };

  return (
    <AppBar
      component="div"
      className={classes.secondaryBar}
      color="primary"
      position="static"
      elevation={0}
    >
      <Toolbar>
        <Grid container alignItems="center" spacing={1}>
          <Grid container item xs>
            <span style={{ maxWidth: 96, paddingRight: '1%' }}><img src={logo} alt="Logo" style={{ width: '100%' }} /></span>
            <Typography color="inherit" variant="h5" component="h1">
              Email Group Manager
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color="inherit" className={classes.iconButtonAvatar} onClick={handleClickAccount}>
              {user.image.length > 0 ? <Avatar src={user.image} alt={user.name} /> : <Avatar alt={user.name} >{user.name.length > 1 ? user.name.charAt(0) : ""}</Avatar>}
            </IconButton>
            <Menu
              anchorEl={accountAnchorEl}
              open={openAccount}
              onClose={handleCloseAccount}
              onClick={handleCloseAccount}
              PaperProps={{
                elevation: 0,
                sx: {
                  width: 300,
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Link href={APP_CONFIG.USER_GUIDE_URL} target="_blank" rel="noreferrer" underline="none">
                <MenuItem>
                  <ListItemIcon>
                    <FileTextIcon fontSize="small" />
                  </ListItemIcon>
                  User Guide
                </MenuItem>
              </Link>
              <Link href={getGmailMailTo(APP_CONFIG.GET_HELP_EMAIL_TO, APP_CONFIG.GET_HELP_EMAIL_SUBJECT)} target="_blank" rel="noreferrer" underline="none">
                <MenuItem>
                  <ListItemIcon>
                    <HelpIcon fontSize="small" />
                  </ListItemIcon>
                  Get Help
                </MenuItem>
              </Link>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogOutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};
