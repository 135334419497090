import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckboxList from './CheckboxList';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import GroupsActionToolbar from './GroupsActionToolbar';
import GroupsText from './GroupsText';
import PrivateGroupsList from './PrivateGroupsList';
import DefaultGroupsList from './DefaultGroupsList';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
        margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
        margin: `5px 0 0 ${theme.spacing(9)}px`,
    },
}));

const getCustomLists = (publicGroups, checkedCustom, handleSelectCustom, handleSubscriptionDialog) => {
    var length = publicGroups.length;
    if (length === 0) {
        return <GroupsText text={"No groups to show"}/>;
    }
    var columns = 3;
    var maxItems = Math.floor(length / columns);
    var end = 0;
    if (maxItems > 20) {
        if (length % columns !== 0) {
            end = maxItems + 1;
        } else {
            end = maxItems;
        }
    } else {
        end = 20;
    }
    var returnElements = [];
    for (var i = 0; i <= (columns - 1); i++) {
        returnElements.push(
            <Grid key={i} item xs={12} md={(12 / columns)}>
                <CheckboxList groups={publicGroups.slice(i * end, end * (i + 1))} isCustom={true} checked={checkedCustom} handleSelectCustom={handleSelectCustom}
                    handleSubscriptionDialog={handleSubscriptionDialog} />
            </Grid>);
    }

    return <Grid container spacing={2}>{returnElements}</Grid>;
};

export default function SubheaderDividers(props) {
    const { publicGroups, checkedCustom, handleSelectCustom } = props;
    const classes = useStyles();

    return (
        <List key={0} className={classes.root}>
            <Paper className="email-groups" elevation={3}>
                <ListItem key={0.1}>
                    <ListItemText primary="Default Groups" />
                </ListItem>
                <li key={0.11}>
                    {props.defaultGroups.length !== 0 ? 
                    <DefaultGroupsList groups={props.defaultGroups}/>
                    :
                    <GroupsText text={"No groups to show"}/>}
                </li>
            </Paper>
            <Paper className="email-groups" elevation={3}>
                <ListItem key={0.2}>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Grid item xs={12}>
                            <ListItemText primary="Public Groups" />
                        </Grid>
                        <Grid item xs={12}>
                            <GroupsActionToolbar checkedPublicGroups={props.checkedCustom} selected={props.selected} handleClearSelectedPublicGroups={props.handleClearSelectedPublicGroups} handleSubscriptionDialog={props.handleSubscriptionDialog}/>
                        </Grid>
                    </Grid>
                </ListItem>
                <li key={0.21}>
                    {getCustomLists(publicGroups, checkedCustom, handleSelectCustom, props.handleSubscriptionDialog)}
                </li>
            </Paper>
            <Paper className="email-groups" elevation={3}>
                <ListItem key={0.3}>
                    <ListItemText primary="Private Groups" />
                </ListItem>
                <li>
                {props.privateGroups.length !== 0 ? <PrivateGroupsList groups={props.privateGroups} handleSubscriptionDialog={props.handleSubscriptionDialog} /> :
                    <GroupsText text={"No groups to show"}/>}
                </li>
            </Paper>
        </List>
    );
}